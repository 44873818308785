'use client';

import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import { default as NextImage, ImageProps as NextImageProps } from 'next/image';

import { getImageSrcFromBlob } from '@lib/shared/typescript-utils';

import { ImagePreview } from '../assets/imagePreviews';

function parseSourceUrl(src: string | StaticImport): string | StaticImport {
    if (typeof src === 'string') {
        return src.startsWith('http') ? src : getImageSrcFromBlob(src);
    }
    return src;
}

export interface ImageProps extends Omit<NextImageProps, 'width' | 'height' | 'fill'> {
    width?: string | number;
    height?: string | number;
}

export function Image({ width, height, className, ...nextImageProps }: ImageProps) {
    const source = parseSourceUrl(nextImageProps.src);

    return (
        <div style={{ width, height }} className={className}>
            <NextImage
                {...nextImageProps}
                src={source}
                width={0}
                height={0}
                sizes="100vw"
                placeholder="blur"
                blurDataURL={nextImageProps.blurDataURL ?? ImagePreview.IMAGE_COMING_COON}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'inherit',
                    objectPosition: 'inherit',
                    maxWidth: 'inherit',
                    maxHeight: 'inherit',
                }}
            />
        </div>
    );
}
