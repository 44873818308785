'use client';

import { MixpanelTrack } from '@lib/machine-parts/storefront/utils';
import { PortableText as PortableTextReact, PortableTextProps as PortableTextReactProps } from '@portabletext/react';
import { PortableTextReactComponents } from '@portabletext/react/src/types';

import { Link } from '../nextjs/atoms/Link';
import { ButtonVariant } from './constants';

interface PortableTextProps {
    value: PortableTextReactProps['value'];
}

const portableTextComponents: Partial<PortableTextReactComponents> = {
    list: {
        bullet: ({ children }) => {
            return <ul className="list-disc pl-5">{children}</ul>;
        },
        number: ({ children }) => {
            return <ol className="list-decimal pl-5">{children}</ol>;
        },
    },
    listItem: {
        bullet: ({ children }) => {
            return <li>{children}</li>;
        },
        number: ({ children }) => {
            return <li>{children}</li>;
        },
    },
    marks: {
        annotationLinkExternal: ({ children, value }) => {
            return (
                <Link
                    mixpanel={{
                        eventName: 'External link',
                        properties: {
                            to: value?.url ?? value,
                        },
                    }}
                    href={value?.url ?? value}
                    openInNewTab={value.newWindow}
                    variant={ButtonVariant.None}
                    className="underline"
                >
                    {children}
                </Link>
            );
        },
        annotationLinkInternal: ({ children, value }) => {
            return (
                <Link
                    mixpanel={{
                        eventName: 'Internal link',
                        properties: {
                            to: value?.slug?.current ?? value,
                        },
                    }}
                    href={value?.slug?.current ?? value}
                >
                    {children}
                </Link>
            );
        },
        annotationLinkEmail: ({ children, value }) => {
            return (
                <MixpanelTrack eventName="Email Link" properties={{ email: value?.email ?? value }}>
                    <a className="underline underline-offset-4" href={`mailto:${value?.email ?? value}`}>
                        {children}
                    </a>
                </MixpanelTrack>
            );
        },
        annotationLinkTelephone: ({ children, value }) => {
            return (
                <MixpanelTrack eventName="Telephone Link" properties={{ email: value?.email ?? value }}>
                    <a className="underline underline-offset-4" href={`tel:${value?.telephone ?? value}`}>
                        {children}
                    </a>
                </MixpanelTrack>
            );
        },
    },
};

export function PortableTextClient({ value }: PortableTextProps) {
    return (
        <div>
            <PortableTextReact value={value} components={portableTextComponents} />
        </div>
    );
}
