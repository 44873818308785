'use client';

import {
    DeepMap,
    DeepPartial,
    DeepRequired,
    FieldError,
    FieldErrorsImpl,
    FieldValues,
    Path,
    UseFormReturn,
} from 'react-hook-form';

const getValue = <T extends FieldValues>(
    o: Partial<Readonly<DeepMap<DeepPartial<T>, boolean>> | FieldErrorsImpl<DeepRequired<T>>>,
    p: Path<T>,
) => p.split('.').reduce((c, k) => (c as unknown as Record<string, FieldError>)?.[k], o as unknown as FieldError);

interface IsErrorProps<Form extends FieldValues> {
    entryPoint: Path<Form>;
    formState: Pick<UseFormReturn<Form>['formState'], 'dirtyFields' | 'touchedFields' | 'errors'>;
    validateUntouched?: boolean;
}
export function getFormError<Form extends FieldValues>({
    entryPoint,
    formState: { dirtyFields, touchedFields, errors },
    validateUntouched = false,
}: IsErrorProps<Form>) {
    if (validateUntouched && !(getValue<Form>(dirtyFields, entryPoint) || getValue<Form>(touchedFields, entryPoint))) {
        return true;
    }
    const error: string | undefined = getValue<Form>(errors, entryPoint)?.message;
    if (error) return error;
    return false;
}

export function isFormValid<Form extends FieldValues>({ validateUntouched, ...input }: IsErrorProps<Form>) {
    const {
        entryPoint,
        formState: { dirtyFields, touchedFields },
    } = input;
    return (
        (validateUntouched || getValue<Form>(dirtyFields, entryPoint) || getValue<Form>(touchedFields, entryPoint)) &&
        !getFormError(input)
    );
}
