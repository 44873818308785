import { PropsWithChildren, ReactElement, ReactNode } from 'react';

export interface CardProps extends PropsWithChildren {
    title?: string;
    titleClassname?: string;
    childrenClassname?: string;
    description?: string;
    icon?: ReactElement;
    banner?: ReactNode;
    footer?: ReactNode;
    className?: string;
}

export function Card({
    description,
    icon,
    banner,
    footer,
    children,
    className,
    title,
    titleClassname = '',
    childrenClassname = '',
}: CardProps) {
    return (
        <div className={`w-full h-fit p-4 flex flex-row items-center gap-x-4 bg-white ${className}`}>
            {banner}
            <div className="w-full flex flex-col gap-y-4 h-full">
                {(title || description || icon) && (
                    <div className="flex flex-row justify-between gap-2">
                        {(title || description) && (
                            <div className="flex flex-col gap-1">
                                {title && <span className={`font-bold ${titleClassname}`}>{title}</span>}
                                {description && <span>{description}</span>}
                            </div>
                        )}
                        {icon}
                    </div>
                )}
                {children && <div className={`flex flex-col gap-2 ${childrenClassname}`}>{children}</div>}
            </div>
            {footer}
        </div>
    );
}
