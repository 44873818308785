'use client';

import { ReactElement, useMemo } from 'react';
import {
    DefaultValues,
    FieldValues,
    FormProvider,
    Resolver,
    SubmitErrorHandler,
    useForm,
    UseFormReturn,
} from 'react-hook-form';

import { formatTranslation, ModalStatus } from '@lib/machine-parts/storefront/utils';

import { Button } from '../../../atoms';
import { Modal, ModalProps } from './Modal.client';

interface TranslationType {
    button: Record<ModalStatus, string>;
}

interface FormModalProps<Form extends FieldValues> extends Omit<ModalProps, 'children'> {
    onSubmit: (data: Form) => void;
    onError?: SubmitErrorHandler<Form>;
    children?: (methods: UseFormReturn<Form>) => ReactElement | ReactElement[];
    status?: ModalStatus;
    defaultValues?: DefaultValues<Form>;
    resolver?: Resolver<Form>;
    translation?: TranslationType;
    customOnClick?: boolean;
    'data-testid'?: string;
}

export function FormModal<Form extends FieldValues>({
    children,
    onSubmit,
    onError,
    status = 'IDLE',
    defaultValues,
    resolver,
    translation,
    description,
    customOnClick = false,
    'data-testid': testId,
    ...modalProps
}: FormModalProps<Form>) {
    const t = formatTranslation(translation);
    const formMethods = useForm<Form>({ defaultValues, resolver });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formMethods.handleSubmit(onSubmit, onError ?? console.error)();
    };

    const button = useMemo(
        () => (
            <Button
                type="submit"
                disabled={status === 'LOADING'}
                onClick={
                    customOnClick
                        ? ['IDLE', 'LOADING'].includes(status)
                            ? () => onSubmit(formMethods.getValues())
                            : modalProps.onClose
                        : undefined
                }
                data-testid={testId ? `${testId}-submit-button` : undefined}
                icon
                className="flex-grow"
            >
                {t(`button.${status}`)}
            </Button>
        ),
        [status, customOnClick, modalProps.onClose, testId, t, onSubmit, formMethods],
    );

    return (
        <Modal {...modalProps} {...(status === 'IDLE' && { description })} data-testid={testId}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <FormProvider key="form" {...formMethods}>
                    {children?.(formMethods)}
                </FormProvider>
                <div key="button" className="flex flex-row justify-end gap-4">
                    {button}
                </div>
            </form>
        </Modal>
    );
}
