import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export enum MessageBoxType {
    None = 'none',
    Error = 'error',
    Info = 'info',
}

export interface MessageBoxProps {
    id: string;
    type?: MessageBoxType;
    title: string;
    messages?: string | string[];
    className?: string;
}

const Styling: Record<MessageBoxType, string> = {
    [MessageBoxType.None]: '',
    [MessageBoxType.Error]: 'bg-red-100 border-red-700 text-red-700',
    [MessageBoxType.Info]: 'bg-green-100 border-green-700 text-green-700',
};

export function MessageBox({
    id,
    type = MessageBoxType.None,
    title,
    messages,
    className: extraClasses = '',
}: MessageBoxProps) {
    const className = `border-l-4 rounded py-2 px-4 w-full ${Styling[type]} ${extraClasses}`;
    return (
        <div id={id} data-testid={id} className={className}>
            <h3 className="font-bold">{title}</h3>
            {(typeof messages === 'string' ? [messages] : messages)?.map((m, index) => (
                <div key={index}>{m}</div>
            ))}
        </div>
    );
}

export function getMessagesFromFormErrors(
    errors?: FieldError | Partial<FieldErrorsImpl> | Merge<FieldError, Partial<FieldErrorsImpl>>,
): string[] {
    if (!errors) return [];
    if (errors.message) return [errors.message as string];
    return Object.values(errors)?.reduce((messages, v) => [...messages, ...(getMessagesFromFormErrors(v) ?? [])], []);
}
