import { ReactNode } from 'react';

interface ContactCardProps {
    slots?: { button?: ReactNode; image?: ReactNode };
    title?: string;
    description?: string;
    className?: string;
}

export function ContactCard({ slots, title, description, className = '' }: ContactCardProps) {
    return (
        <div className={`bg-white p-4 pt-6 flex flex-col justify-between gap-4 ${className}`}>
            <div className={'flex flex-row md:flex-col items-between gap-4'}>
                {slots?.image && slots.image}
                <div className={'flex flex-col self-stretch gap-2'}>
                    {title && <h1 className={'text-3xl md:text-center'}>{title}</h1>}
                    {description && <p className={'text-gray-600 md:text-center'}>{description}</p>}
                </div>
            </div>
            {slots?.button && slots.button}
        </div>
    );
}
