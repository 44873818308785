'use client';

import { CheckIcon } from '@heroicons/react/solid';
import React, { ForwardedRef, forwardRef, InputHTMLAttributes, ReactElement, useState } from 'react';
import { SpinnerIcon } from '../../icons/SpinnerIcon';

const DISABLED_CLASSES = 'disabled:opacity-50 disabled:cursor-not-allowed';
const DEFAULT_CLASSES = {
    primary:
        'w-full px-5 py-3 text-base shadow-sm bg-transparent border-1 focus:shadow-outline focus:border-brand-brown disabled:bg-slate-200',
    secondary:
        'shadow-sm focus:ring-brand-brown focus:border-brand-brown block w-full sm:text-sm border-gray-300 rounded-md',
};

type InputVariant = 'primary' | 'secondary';
type Props = {
    fieldType?: 'textarea';
    testId?: string;
    className?: string;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onEnter?: () => void;
    autocomplete?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    altBorder?: boolean;
    error?: boolean | string;
    valid?: boolean;
    variant?: InputVariant;
    loading?: boolean;
};

export type InputProps = Props & InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement>;
export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
    (
        {
            fieldType,
            className,
            handleChange,
            onEnter,
            testId,
            autocomplete,
            startIcon,
            endIcon,
            type = 'text',
            altBorder = false,
            error,
            valid,
            variant = 'primary',
            loading = false,
            ...props
        },
        ref,
    ) => {
        const [revealPassword, setRevealPassword] = useState(false);
        const classes = `${DEFAULT_CLASSES[variant]} ${DISABLED_CLASSES} ${startIcon ? 'pl-14' : ''} ${
            type === 'password' ? 'pr-16' : valid !== undefined ? 'pr-10' : ''
        } ${altBorder ? 'border-gray-300' : ''} ${
            error && typeof error === 'boolean' ? 'border-b-red-500 border-b-2' : ''
        } ${className ?? ''}`;

        const handleKey = onEnter
            ? (e: { key: string }) => {
                  if (e.key === 'Enter') onEnter();
              }
            : undefined;

        const fieldProps: InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement> & {
            'data-testid'?: string;
        } = {
            'data-testid': testId,
            className: classes,
            type: type === 'password' && revealPassword ? 'text' : type,
            onChange: (event) => handleChange?.(event),
            onKeyPress: handleKey,
            autoComplete: autocomplete,
            ...props,
        };

        return (
            <div className="w-full flex flex-col h-auto">
                <div className="relative w-full flex flex-col h-auto">
                    {Boolean(startIcon) && (
                        <div
                            className={
                                'absolute z-10 w-5 h-5 ml-5 top-1/2 transform -translate-y-1/2 flex items-center justify-center'
                            }
                        >
                            {startIcon}
                        </div>
                    )}
                    {fieldType === 'textarea' ? (
                        <textarea {...fieldProps} ref={ref as ForwardedRef<HTMLTextAreaElement>} />
                    ) : (
                        <input {...fieldProps} ref={ref as ForwardedRef<HTMLInputElement>} />
                    )}
                    {loading || endIcon ? (
                        <div className="absolute top-1/2 right-0 transform -translate-y-1/2 mr-2">
                            {loading ? <SpinnerIcon height={30} /> : endIcon}
                        </div>
                    ) : type === 'password' ? (
                        <button
                            type="button"
                            className="absolute top-1/2 right-0 transform -translate-y-1/2 mr-4 text-brand-brown font-light opacity-30"
                            onClick={() => setRevealPassword(!revealPassword)}
                        >
                            {revealPassword ? 'Hide' : 'Show'}
                        </button>
                    ) : (
                        valid === true && (
                            <div className="absolute top-1/2 right-0 transform -translate-y-1/2 mr-2">
                                <CheckIcon className="h-5 mr-1 text-green-400" />
                            </div>
                        )
                    )}
                </div>
                {typeof error === 'string' && <span className="text-red-500 text-xs mt-2">{error}</span>}
            </div>
        );
    },
);

export default Input;
