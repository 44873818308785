'use client';

import { useCartLine } from '@shopify/hydrogen-react';
import { ImageWithFallback } from '../molecules/ImageWithFallback';

export function CartLineImage() {
    const { merchandise } = useCartLine();
    if (!merchandise?.image?.url) return null;

    return (
        <ImageWithFallback
            src={merchandise.image.url}
            alt=""
            width={98}
            height={98}
            className="bg-white border border-black border-opacity-5 object-cover object-center"
        />
    );
}
