import React from 'react';

interface FormGroupProps {
    children: React.ReactNode;
    label?: string;
    errorMessage?: string;
    htmlFor?: string;
}

export function FormGroup({ label, children, htmlFor, errorMessage }: FormGroupProps) {
    return (
        <div className={'flex flex-col gap-1 items-stretch'}>
            {label && (
                <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
                    {label}
                </label>
            )}
            {children}
            {errorMessage && <p className={'text-sm text-red-700'}>{errorMessage}</p>}
        </div>
    );
}
