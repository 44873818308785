import { StringUnion } from './string-union';

export function parseStringAsUTCDate(str: string): Date {
    const parsed = new Date(str);

    return toUTC(parsed);
}

const unitsOfTime = StringUnion('Year', 'Month', 'Date', 'Hours', 'Minutes', 'Seconds', 'Milliseconds');
export type UnitOfTime = typeof unitsOfTime.type;

export function toUTC(date: Date, precision: UnitOfTime = 'Milliseconds'): Date {
    const utc = {
        Year: date.getUTCFullYear(),
        Month: date.getUTCMonth(),
        Date: date.getUTCDate(),
        Hours: date.getUTCHours(),
        Minutes: date.getUTCMinutes(),
        Seconds: date.getUTCSeconds(),
        Milliseconds: date.getUTCMilliseconds(),
    };
    let precisionReached = false;
    const utcWithPrecision = unitsOfTime.values
        .filter((unit) => {
            if (precision === unit) {
                precisionReached = true;

                return true;
            }
            return !precisionReached;
        })
        .reduce(
            (dateWithPrecision, unit) => ({ ...dateWithPrecision, [unit]: utc[unit] }),
            {} as Record<string, number>,
        );

    // @ts-expect-error bit unsafe, but it does work fine
    return new Date(Date.UTC(...Object.values(utcWithPrecision)));
}

export function toUnixTimeStamp(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}

export function getMonthsBetweenDates(dateFrom: Date, dateTo: Date) {
    return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
}

export function getDaysBetweenDates(dateFrom: Date, dateTo: Date) {
    // new Date() is needed because sometimes typescript does not understand that dateTo or dateFrom are real dates
    const differenceInTime = new Date(dateTo).getTime() - new Date(dateFrom).getTime();
    return Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
}

export function getMinutesBetweenDates(dateFrom: Date, dateTo: Date) {
    // new Date() is needed because sometimes typescript does not understand that dateTo or dateFrom are real dates
    const differenceInTime = new Date(dateTo).getTime() - new Date(dateFrom).getTime();
    return Math.floor(differenceInTime / (1000 * 60));
}

export function isOneOrMoreDaysBefore(dateToTest: Date, referenceDate: Date) {
    return new Date(dateToTest).setHours(12, 0, 0, 0) < new Date(referenceDate).setHours(12, 0, 0, 0);
}

export function isSameDay(dateToTest: Date, referenceDate: Date) {
    return new Date(dateToTest).setHours(12, 0, 0, 0) === new Date(referenceDate).setHours(12, 0, 0, 0);
}

export function isSameYear(dateToTest: Date, referenceDate: Date) {
    return dateToTest.getUTCFullYear() === referenceDate.getUTCFullYear();
}

export function fmtDateToSimpleDate(date: Date): string {
    if (typeof date === 'string') {
        date = new Date(Date.parse(date));
    }
    return date.toISOString().split('T')[0];
}

export function dateToSimpleDate(date: Date): Date {
    return new Date(fmtDateToSimpleDate(date));
}

export function calculateAgeInYears(birthday: Date, referenceDate: Date = new Date(Date.now())) {
    // birthday is a date
    const ageDifMs = referenceDate.getTime() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function addDaysToDate(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addMonthsToDate(date: Date, months: number): Date {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}
