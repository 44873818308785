'use client';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from '@lib/machine-parts/storefront/utils';
import { CartLineUpdateInput } from '@shopify/hydrogen-react/storefront-api-types';

interface CartLineInputFieldProps {
    quantity?: number;
    merchandise?: { id?: string };
    lines?: ({ id?: string; merchandise?: { id?: string }; quantity?: number } | undefined)[];
    linesUpdate: (lines: CartLineUpdateInput[]) => void;
    onInputType?: (amount: number) => void;
}

export function CartLineInputField({
    quantity,
    merchandise,
    lines,
    linesUpdate,
    onInputType,
}: CartLineInputFieldProps) {
    const { register, getValues, setValue, trigger } = useForm<{ typedQuantity: number }>({
        mode: 'onChange',
        values: { typedQuantity: quantity ?? 1 },
        resolver: yupResolver(
            yup.object().shape({
                typedQuantity: yup.number().min(1).max(999),
            }),
        ),
    });

    const onBlur = async () => {
        const newQuantity = parseInt(String(getValues('typedQuantity')));
        const isValid = await trigger('typedQuantity');
        if (isValid) {
            linesUpdate(
                lines?.map((line) => ({
                    id: line?.id ?? '',
                    merchandiseId: line?.merchandise?.id ?? '',
                    quantity: merchandise?.id === line?.merchandise?.id ? newQuantity : line?.quantity,
                })) ?? [],
            );
            onInputType?.(newQuantity);
        } else {
            setValue('typedQuantity', quantity ?? 0);
        }
    };

    return (
        <input
            {...register('typedQuantity')}
            className="w-10 px-0 text-gray-900 text-center font-bold"
            onBlur={onBlur}
            data-testid="quantity"
        />
    );
}
