import { ReactNode } from 'react';

export const gridColsClassnames = {
    1: 'grid-cols-1',
    2: 'grid-cols-1 md:grid-cols-2',
    3: 'grid-cols-1 md:grid-cols-3',
    4: 'grid-cols-1 md:grid-cols-4',
};

type GridCols = keyof typeof gridColsClassnames;

interface PageContentProps {
    children?: ReactNode;
    cols?: GridCols;
}

export function PageContent({ children, cols }: PageContentProps) {
    return (
        <div className={`p-8 mx-auto w-full max-w-7xl grid gap-8 ${cols ? gridColsClassnames[cols] : ''}`}>
            {children}
        </div>
    );
}
