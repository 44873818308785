import { HTMLProps } from 'react';

export interface LabelledItemProps extends HTMLProps<HTMLDivElement> {
    label: string;
    value?: string;
    capitalize?: boolean;
}
export function LabelledItem({ label, value, capitalize, ...divProps }: LabelledItemProps) {
    return (
        <div className="flex flex-col" {...divProps}>
            <span className={`text-sm ${!value ? 'text-red-700' : 'text-gray-400'}`}>{label}</span>
            <span
                className={`text-ellipsis ${!value ? 'text-red-700' : 'text-black'} overflow-hidden ${
                    capitalize ? 'capitalize' : undefined
                }`}
                data-testid="value"
            >
                {value ? value : '-'}
            </span>
        </div>
    );
}
