import { PortableText, PortableTextComponentProps, PortableTextMarkComponentProps } from '@portabletext/react';

import { ImageWithFallback } from '../molecules/ImageWithFallback';
import { Link } from '../nextjs/atoms/Link';
import { ButtonVariant } from './constants';
import { EmailData, ImageData, LinkData, RichTextBlockProps } from '@lib/machine-parts/storefront/utils';

function createLink({ children, value }: PortableTextMarkComponentProps<LinkData>) {
    if (!value) return children;
    return (
        <Link
            variant={ButtonVariant.None}
            href={value.url}
            className={`inline-block underline underline-offset-4`}
            mixpanel={value.mixpanelEventName ? { eventName: value.mixpanelEventName } : undefined}
        >
            {children}
        </Link>
    );
}

const defaultStyles = {
    text: 'leading-[1.75]',
    list: 'ml-[1.25em] my-4',
    listItem: 'my-2 pl-[0.375em]',
    heading: 'font-bold text-center',
};

const portableTextComponents: RichTextBlockProps['components'] = {
    types: {
        image: ({ value: { url, alt } }: PortableTextComponentProps<ImageData>) => (
            <ImageWithFallback
                src={url}
                width={'100%'}
                height={'100%'}
                className={'object-cover object-center max-h-[800px]'}
                alt={alt ?? 'Image'}
            />
        ),
    },
    list: {
        bullet: ({ children }) => <ul className={`${defaultStyles.list} list-disc`}>{children}</ul>,
        number: ({ children }) => <ol className={`${defaultStyles.list} list-decimal`}>{children}</ol>,
    },
    listItem: {
        bullet: ({ children }) => <li className={`${defaultStyles.text} ${defaultStyles.listItem}`}>{children}</li>,
        number: ({ children }) => <li className={`${defaultStyles.text} ${defaultStyles.listItem}`}>{children}</li>,
    },
    marks: {
        link: createLink,
        linkEmail: ({ value, ...props }: PortableTextMarkComponentProps<EmailData>) => {
            if (!value) return props.children;
            const params = {
                ...(value?.subject ? { subject: value?.subject } : {}),
                ...(value?.body ? { body: value?.body } : {}),
            };
            const url = `mailto:${value.email}${
                Object.values(params).length ? `?${new URLSearchParams(params).toString()}` : ''
            }`;
            return createLink({
                ...props,
                value: value ? { ...value, _type: 'link', newWindow: false, url } : undefined,
            });
        },
        alignCenter: (props) => <div className="text-center">{props.children}</div>,
    },
    block: {
        h1: ({ children }) => (
            <h1 className={`${defaultStyles.heading} text-3xl lg:text-6xl pt-16 mb-16`}>{children}</h1>
        ),
        h2: ({ children }) => (
            <h1 className={`${defaultStyles.heading} text-2xl lg:text-5xl pt-12 mb-12`}>{children}</h1>
        ),
        h3: ({ children }) => <h1 className={`${defaultStyles.heading} text-xl lg:text-4xl pt-8 mb-8`}>{children}</h1>,
        h4: ({ children }) => <h1 className={`${defaultStyles.heading} text-lg lg:text-3xl pt-4 mb-4`}>{children}</h1>,
        h5: ({ children }) => <h1 className={`${defaultStyles.heading} text-lg lg:text-2xl pt-4 mb-4`}>{children}</h1>,
        h6: ({ children }) => <h1 className={`${defaultStyles.heading} text-lg lg:text-xl pt-4 mb-4`}>{children}</h1>,
        normal: ({ children }) => <div className={`${defaultStyles.text} my-[1.25em]`}>{children}</div>,
        blockquote: ({ children }) => <p className={`${defaultStyles.text} bg-gray-200 p-2`}>{children}</p>,
    },
};

export function RichTextBlock(props: RichTextBlockProps) {
    return <PortableText {...props} components={portableTextComponents} />;
}
