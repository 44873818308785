import { CheckCircleIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/outline';
import { formatTranslation, ModalStatus } from '@lib/machine-parts/storefront/utils';

interface DefaultTranslationType {
    loading: string;
    failed: string;
    success: string;
}

interface GetModalContentProps<TranslationType> {
    status: ModalStatus;
    translation?: TranslationType;
}

export function getModalContent<TranslationType extends DefaultTranslationType = DefaultTranslationType>({
    status,
    translation,
}: GetModalContentProps<TranslationType>) {
    const t = formatTranslation<DefaultTranslationType>(translation);
    switch (status) {
        case 'LOADING':
            return (
                <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                    <RefreshIcon className={`h-16 text-brand-brown animate-spin`} data-testid={'loadingIcon'} />
                    <p>{t('loading')}</p>
                </div>
            );
        case 'FAILED':
            return (
                <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                    <XCircleIcon className={`h-16 text-brand-red`} data-testid={'xCircle'} />
                    <p>{t('failed')}</p>
                </div>
            );
        case 'SUCCESS':
            return (
                <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                    <CheckCircleIcon className={`h-16 text-brand-green`} data-testid={'checkCircle'} />
                    <p>{t('success')}</p>
                </div>
            );
        default:
            return undefined;
    }
}
