'use client';

import { ElementType, ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { useMixpanel } from '@lib/machine-parts/storefront/utils';

import { SelectIcon, SpinnerIcon } from '../icons';
import { ImageWithFallback } from '../molecules';

const DISABLED_CLASSES = 'disabled:bg-slate-200 disabled:opacity-50 disabled:cursor-not-allowed';
const VARIANT_CLASSES = {
    default:
        'bg-transparent relative text-sm lg:text-base w-full border border-brand-black px-5 py-2 h-full items-center text-left cursor-default focus:outline-none focus:ring-1 focus:ring-brand-brown focus:border-brand-brown',
    register:
        'w-full px-5 py-3 text-base shadow-sm bg-transparent border-1 focus:shadow-outline focus:border-brand-brown border text-left border-gray-300 flex items-center gap-4 pr-10 relative',
};

const VARIANT_PLACEHOLDER_CLASSES = {
    default: '',
    register: 'text-gray-500',
};

export const getListSelectVariantClasses = (variant: keyof typeof VARIANT_CLASSES, selection?: string) => {
    return `${VARIANT_CLASSES[variant]} ${DISABLED_CLASSES} ${!selection ? VARIANT_PLACEHOLDER_CLASSES[variant] : ''}`;
};

export interface ListSelectOption<Type = string> {
    name: string;
    value: Type;
    image?: string;
    icon?: ReactNode;
    detail?: string;
}

export interface ListSelectProps<OptionType = string> {
    id: string;
    options: ListSelectOption<OptionType>[];
    selected?: OptionType;
    handleChange: (value: OptionType) => void;
    truncate?: boolean;
    placeholder?: string;
    className?: string;
    containerClassName?: string;
    disabled?: boolean;
    loading?: boolean;
    error?: boolean;
    variant?: keyof typeof VARIANT_CLASSES;
    position?: 'north' | 'south';
    onBlur?: () => void;
}

export function ListSelect<OptionType extends string = string>({
    id,
    options,
    selected,
    handleChange,
    truncate = true,
    placeholder = '',
    className = '',
    disabled = false,
    loading = false,
    error = false,
    containerClassName = '',
    variant = 'default',
    position = 'south',
    onBlur,
}: ListSelectProps<OptionType>) {
    const currentSelection = options?.find((option) => option.value === selected);
    const classes = `${getListSelectVariantClasses(variant, currentSelection?.name)} ${className} ${
        error ? 'border-b-red-500 border-b-2' : ''
    }`;
    const mixpanel = useMixpanel();

    return (
        <Listbox<ElementType, OptionType>
            as="div"
            value={selected}
            onChange={handleChange}
            className={`contents ${containerClassName}`}
            disabled={disabled}
        >
            {({ open }) => (
                <div className={`relative ${className}`}>
                    <Listbox.Button data-testid={`listbox-select-button-${id}`} className={classes}>
                        {currentSelection?.icon && currentSelection.icon}
                        <span className="truncate flex-1 flex flex-row ">
                            <span className="flex-1">
                                {currentSelection?.name ? currentSelection.name : placeholder}
                            </span>
                            {currentSelection?.detail && (
                                <span className="text-gray-500">{currentSelection?.detail}</span>
                            )}
                        </span>
                        <span
                            className={`absolute inset-y-0 right-4 px-2 flex items-center pointer-events-none ${
                                disabled ? 'text-gray-400' : 'text-brand-brown'
                            } ${open ? 'rotate-180' : ''}`}
                        >
                            {loading ? (
                                <SpinnerIcon width="25" height="25" aria-hidden="true" />
                            ) : (
                                <SelectIcon aria-hidden="true" />
                            )}
                        </span>
                    </Listbox.Button>

                    {options.length > 0 && (
                        <Listbox.Options
                            className={`absolute z-10 mt-1 max-h-48 md:max-h-72 ${
                                position === 'north' ? '-translate-y-72' : ''
                            } w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                        >
                            {options.map(({ name, value, image, detail, icon }) => {
                                return (
                                    <Listbox.Option
                                        data-testid={name}
                                        aria-label={name}
                                        key={`${value}`}
                                        value={value}
                                        className={({ active, selected }) =>
                                            `relative cursor-default select-none py-2 px-3
                                            ${active || selected ? 'bg-brand-background' : 'bg-white'}`
                                        }
                                        onBlur={onBlur}
                                    >
                                        {({ active, selected }) => (
                                            <div className="flex items-center justify-between">
                                                <div className={'flex flex-row items-center gap-4'}>
                                                    {icon && icon}
                                                    <span
                                                        className={` ${
                                                            truncate && 'truncate'
                                                        } text-sm lg:text-lg flex-shrink-0 ${selected && ''}`}
                                                    >
                                                        {name}
                                                    </span>
                                                    {image && (
                                                        <ImageWithFallback
                                                            src={image}
                                                            className="h-6 w-10 object-contain flex-shrink-0"
                                                            alt={'list-select'}
                                                            mixpanel={mixpanel}
                                                            eventInfo={{
                                                                location: 'ListSelect component',
                                                                item: name,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div className={'flex flex-row gap-2'}>
                                                    {detail && <span>{detail}</span>}

                                                    <span
                                                        className={`inset-y-0 flex items-center ${
                                                            selected ? 'visible' : 'invisible'
                                                        } ${active || selected ? 'bg-brand-background' : 'bg-white'}`}
                                                    >
                                                        <CheckIcon
                                                            className="text-brand-black h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Listbox.Option>
                                );
                            })}
                        </Listbox.Options>
                    )}
                </div>
            )}
        </Listbox>
    );
}

export default ListSelect;
