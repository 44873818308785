'use client';

import { LogoIcon } from '../icons/LogoIcon';
import { Image } from '../molecules/Image';

export enum VendorName {
    VELROQ = 'Velroq',
    SANDVIK = 'Sandvik',
    OEM = 'OEM',
}

type Props = {
    vendor?: string;
    className?: string;
};

export function VendorLogo({ vendor, className = '' }: Props) {
    switch (vendor) {
        case VendorName.VELROQ:
            return <LogoIcon className={`h-w-auto h-6 ${className}`} />;
        case VendorName.SANDVIK:
            return (
                <Image
                    src={'/assets/images/logos/sandvik-logo.png'}
                    alt={'vendor-logo'}
                    width={20}
                    height={20}
                    className={`h-w-auto ${className}`}
                />
            );
        case VendorName.OEM:
            return (
                <Image
                    src={'/assets/images/logos/oem-logo.png'}
                    alt={'vendor-logo'}
                    width={20}
                    height={20}
                    className={`h-w-auto ${className}`}
                />
            );
        default:
            return null;
    }
}
