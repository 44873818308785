'use client';

import { ReactElement, ReactNode } from 'react';

import { Dialog } from '@headlessui/react';

import { Button, ButtonVariant } from '../../../atoms';
import { CloseIcon } from '../../../icons';

export interface ModalProps {
    open?: boolean;
    onClose: () => void;
    title?: ReactNode;
    description?: string;
    children: ReactElement | ReactElement[];
    'data-testid'?: string;
}

export function Modal({
    open = true,
    onClose,
    title,
    description,
    children,
    'data-testid': testId,
    ...dialogProps
}: ModalProps) {
    return (
        <Dialog {...dialogProps} open={open} onClose={() => onClose()}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 px-7" />
            <div
                className="w-full max-w-md max-h-[90vh] sm:max-h-[75vh] p-6 my-8 overflow-scroll text-left align-middle transition-all transform bg-white shadow-xl rounded absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4 z-50"
                data-testid={testId}
            >
                <Dialog.Title as="h3" className="text-3xl font-heading text-brand-black uppercase flex justify-between">
                    <span>{title}</span>
                    <Button onClick={onClose} variant={ButtonVariant.None}>
                        <CloseIcon className="h-4 w-4" />
                    </Button>
                </Dialog.Title>
                {description && <p>{description}</p>}
                {children}
            </div>
        </Dialog>
    );
}
