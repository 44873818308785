import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

type TextInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const TextInput = ({ className = '', children, ...props }: TextInputProps) => {
    const classes = `shadow-sm focus:ring-brand-brown focus:border-brand-brown block w-full sm:text-sm border-gray-300 rounded-md ${className}`;

    return (
        <input className={classes} {...props}>
            {children}
        </input>
    );
};
