'use client';

import { createPopper, Instance, Placement } from '@popperjs/core';
import { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

export type TooltipProps = PropsWithChildren<{
    className?: string;
    content: ReactNode;
    placement?: Placement;
    trigger?: 'hover' | 'click';
    animation?: false | `duration-${number}`;
    arrow?: boolean;
}>;

export const Tooltip: FC<TooltipProps> = ({
    children,
    className,
    content,
    placement = 'top',
    trigger = 'hover',
    animation = 'duration-300',
    arrow = true,
}) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLSpanElement>(null);
    const popperInstance = useRef<Instance>();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (wrapperRef.current && tooltipRef.current) {
            popperInstance.current = createPopper(wrapperRef.current, tooltipRef.current, {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                    { name: 'eventListeners', enabled: true },
                ],
            });
        }
    }, [placement]);

    const show = () => {
        setVisible(true);
        popperInstance.current?.update();
    };

    const hide = () => setTimeout(() => setVisible(false), 100);

    return (
        <>
            <div
                className={classNames(
                    'tooltip absolute z-10 inline-block rounded-lg py-5 px-6 text-sm font-medium shadow-md',
                    animation !== false && `transition-opacity ${animation}`,
                    {
                        'invisible opacity-0': !visible,
                    },
                    className,
                )}
                ref={tooltipRef}
                role="tooltip"
            >
                {content}
                {arrow && <div className="tooltip-arrow" data-popper-arrow />}
            </div>
            <span
                className="w-fit"
                ref={wrapperRef}
                onFocus={show}
                onBlur={hide}
                {...(trigger === 'hover' ? { onMouseEnter: show, onMouseLeave: hide } : { onClick: show })}
            >
                {children}
            </span>
        </>
    );
};
