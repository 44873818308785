'use client';

import { ButtonHTMLAttributes } from 'react';

import { MixpanelTrack } from '@lib/machine-parts/storefront/utils';

import { ButtonVariant, CommonButtonProps, getButtonVariantClasses } from '../../constants';
import { WithIcon } from '../../withIcon';

export type ButtonProps = CommonButtonProps & ButtonHTMLAttributes<HTMLButtonElement> & { loading?: boolean };

export function Button({
    variant = ButtonVariant.Primary,
    mixpanel,
    icon,
    selected,
    children,
    border,
    loading,
    ...buttonProps
}: ButtonProps) {
    buttonProps.className = `${getButtonVariantClasses({
        variant,
        selected,
        border,
    })} ${buttonProps.className ?? ''}`;

    return (
        <MixpanelTrack {...mixpanel}>
            <button {...buttonProps}>
                <WithIcon enabled={Boolean(icon)} loading={loading} variant={variant} {...(icon === true ? {} : icon)}>
                    {children}
                </WithIcon>
            </button>
        </MixpanelTrack>
    );
}
