'use client';

import { SyntheticEvent } from 'react';
import { OverridedMixpanel } from 'mixpanel-browser';

import { Image, ImageProps } from './Image';
import { getImageSrcFromBlob } from '@lib/shared/typescript-utils';

export const PRODUCT_FALLBACK_IMAGE = 'shopify/product-not-found.webp';

export function ImageWithFallback({
    mixpanel,
    eventInfo,
    ...props
}: Omit<ImageProps, 'src'> & {
    mixpanel?: OverridedMixpanel;
    eventInfo?: Record<string, string | undefined>;
    src: string | undefined;
}) {
    const fallbackImage = PRODUCT_FALLBACK_IMAGE;
    return (
        <Image
            {...props}
            src={props.src ?? fallbackImage}
            onError={
                props.onError ??
                ((e) => {
                    const fbi = `${getImageSrcFromBlob(fallbackImage)}`;
                    if (e.currentTarget.src === fbi || e.currentTarget.src.endsWith(PRODUCT_FALLBACK_IMAGE)) return;
                    handleFallbackImage({
                        element: e,
                        fallbackImage: fbi,
                        mixpanel,
                        eventInfo,
                    });
                })
            }
        />
    );
}

interface HandleFallbackImageProps {
    element: SyntheticEvent<HTMLImageElement, Event>;
    fallbackImage: string;
    mixpanel?: OverridedMixpanel;
    eventInfo?: Record<string, string | undefined>;
}

export function handleFallbackImage({ element, fallbackImage, mixpanel, eventInfo }: HandleFallbackImageProps) {
    mixpanel?.track('Broken image', {
        src: element.currentTarget.src,
        alt: element.currentTarget.alt,
        ...eventInfo,
    });
    element.currentTarget.onerror = null;
    element.currentTarget.src = fallbackImage;
    element.currentTarget.srcset = fallbackImage;
    element.currentTarget.loading = 'eager';
}
