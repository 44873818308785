import { MachineIcon } from '../icons/MachineIcon';
import { SpinnerIcon } from '../icons';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    variant?: 'center' | 'inline';
}

export function Loading({ title, variant = 'center', ...props }: LoadingProps) {
    return (
        <div
            {...props}
            className={classNames(
                'flex',
                {
                    'flex-col gap-4 justify-center items-center mx-auto my-32': variant === 'center',
                    'flex-row gap-4 justify-around items-center mx-4 my-4': variant === 'inline',
                },
                props.className ?? '',
            )}
        >
            <MachineIcon
                className={classNames({
                    'w-64 h-64': variant === 'center',
                    'w-32 h-32': variant === 'inline',
                })}
            />
            <div className="flex items-center flex-col text-center lg:flex-row lg:text-left">
                {title && <span className="text-4xl">{title}</span>}
                <SpinnerIcon />
            </div>
        </div>
    );
}
