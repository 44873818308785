import { PropsWithChildren } from 'react';

const classNames = {
    base: 'flex gap-4',
    row: 'flex-row',
    col: 'flex-col',
};

export interface ContainerProps extends PropsWithChildren {
    orientation?: 'row' | 'col';
    colSpan?: number;
}

export function Container({ children, orientation = 'col', colSpan = 1 }: ContainerProps) {
    return <div className={`${classNames.base} ${classNames[orientation]} col-span-${colSpan}`}>{children}</div>;
}
