'use client';

import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Dialog } from '@headlessui/react';

export interface BaseModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: (v?: boolean) => void;
    title?: string;
}

export function BaseModal({ children, isOpen, onClose, title }: BaseModalProps) {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 px-7" />

            <div className="flex flex-col w-full max-w-md p-6 gap-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                {title && (
                    <Dialog.Title as="h3" className="text-lg font-heading text-brand-black">
                        {title}
                    </Dialog.Title>
                )}
                {children}
            </div>
        </Dialog>
    );
}
