'use client';

import { forwardRef, InputHTMLAttributes } from 'react';

const DEFAULT_CONTAINER_CLASSES = 'flex flex-row gap-4 items-center';
const DEFAULT_CLASSES_CHECKBOX = '';
const DEFAULT_CLASSES_LABEL = 'text-brand-brown underline';

interface CheckboxProps {
    label: string;
    containerClasses?: string;
    checkboxClasses?: string;
    labelClasses?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps & InputHTMLAttributes<HTMLInputElement>>(
    ({ label, containerClasses, checkboxClasses, labelClasses, ...props }, ref) => {
        const containerClassName = `${DEFAULT_CONTAINER_CLASSES} ${containerClasses}`;
        const checkboxClassName = `${DEFAULT_CLASSES_CHECKBOX} ${checkboxClasses}`;
        const labelClassName = `${DEFAULT_CLASSES_LABEL} ${labelClasses}`;
        return (
            <div className={containerClassName}>
                <input type="checkbox" ref={ref} className={checkboxClassName} {...props} />
                <label htmlFor={props.id} className={labelClassName}>
                    {label}
                </label>
            </div>
        );
    },
);
