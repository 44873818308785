'use client';

import { useCartLine } from '@shopify/hydrogen-react';

export function CartLineProductTitle() {
    const { merchandise } = useCartLine();
    const title = merchandise?.product?.title ?? merchandise?.title;
    if (!title) return null;

    return <h1 className="text-lg lg:text-2xl font-heading font-bold">{title}</h1>;
}
