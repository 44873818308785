'use client';

import { InputHTMLAttributes } from 'react';

export function RadioInput({ disabled, ...props }: Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>) {
    return (
        <input
            {...props}
            type="radio"
            className={(disabled ? `text-brand-brown pointer-events-none ` : '') + (props.className ?? '')}
            readOnly={disabled ?? props.readOnly}
        />
    );
}
